//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";

export default {

  data() {
    return {
      visible: false,
      loading: false,
      message: '',
      resolve: null,
      okTitle: '',
      cancelTitle: ''
    }
  },

  mounted() {
    Vue.prototype.$showConfirmDialog = this.show;
  },

  methods: {
    show(message, okTitle = 'ОК', cancelTitle = 'Отмена') {
      this.message = message;
      this.visible = true;
      this.loading = false;

      this.okTitle = okTitle;
      this.cancelTitle = cancelTitle;

      // Создадим промис и запомним его callback
      return new Promise((resolve) => this.resolve = resolve);
    },

    onClickOk() {
      this.loading = true;
      this.resolve(true);
      this.loading = false;
      this.visible = false;
    }
  }
}
