//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Popup from './Popup.vue';
import axios from "axios";

/**
 * footer
 */
export default {
  components: {
    Popup
  },
  data() {
    return {
      regCommand: '',
      tgbotPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
    };
  },
  computed: {

  },
  created: () => {

  },
  methods: {
    closeTgbotPopup(){
      this.tgbotPopup.show = false;
    },
    showTgbotPopup(){
      axios
          .post(`/v1/telegram/generate-token`)
          .then(resp => {
           if(resp.data.result == 'ok'){
            this.regCommand = resp.data.token;
            this.tgbotPopup.show = true;
           }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    copyText(text){
      navigator.clipboard.writeText(text)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('views.footer.commandToCoppy'),
              time: 3,
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('views.footer.copyNoSupport'),
              time: 3,
              color: "danger"
            });
          });
    },
  },
  mounted() {

  },
};
