//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * $emit closePopup
 * $emit actionPopup
 */
export default {
  name: "Popup",
  props: {
    show: Boolean, // true/false - Показать/Скрыть
    closeButton: String, // Название кнопки, которая скрывает окно
    actionButton: String, // Название кнопки какого-либо действия
    actionClass: String, // Класс кнопки действия, например btn-success
    size: String // 'lg' - широкое окно, если не указывать- узкое окно
  },
  watch: {
    show: function(val) {
      if (val === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  },
  computed: {
    closeButtonShow() {
      return this.closeButton ? true : false;
    },
    actionButtonShow() {
      return this.actionButton ? true : false;
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    actionPopup() {
      this.$emit("actionPopup");
    }
  }
};
