//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  name: "Preloader",
  computed: {
    ...mapState({
      isShow: state => state.preloader.isShow
    })
  },
  methods: {}
};
