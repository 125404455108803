class Permissions {

  constructor(){
    this.nextUpdated = 0;
    this.permList = JSON.parse(localStorage.getItem("permissions"));
    this.roleList = JSON.parse(localStorage.getItem("roles"));
  }

  checkPerm(name) {
    let nowDate = new Date();
    if(this.nextUpdated < nowDate.getTime()){
      this.nextUpdated = nowDate.getTime() + 500; // Данные из localStorage тянем не чаще, чем раз в 500мс
      this.permList = JSON.parse(localStorage.getItem("permissions"));
    }

    let result = false;
    for (let key in this.permList) {
      if (this.permList[key] === name) {
        result = true;
      }
    }
    return result;
  }

  checkRole(name) {
    let nowDate = new Date();
    if(this.nextUpdated < nowDate.getTime()){
      this.nextUpdated = nowDate.getTime() + 500; // Данные из localStorage тянем не чаще, чем раз в 500мс
      this.roleList = JSON.parse(localStorage.getItem("roles"));
    }

    let result = false;
    for (let key in this.roleList) {
      if (this.roleList[key] === name) {
        result = true;
      }
    }
    return result;
  }
}

export default Permissions;
