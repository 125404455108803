import Permissions from "@/components/libraries/Permissions";
const permissions = new Permissions();

export default {

  methods: {
      checkPermission(name){
          return permissions.checkPerm(name);
      },
      checkRole(name){
          return permissions.checkRole(name);
      }
  }
};
