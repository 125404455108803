
import simplebar from "simplebar-vue";
import i18n from "../i18n";
import { layoutComputed } from "@/state/helpers";
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      legal_name: state => state.legal_name.legal_name,
      legal_request: state => state.legal_name.request
    }),
    ...layoutComputed,
    currentFlagPath: function(){
      let result = '';
      //i18n.locale
      for(let key in this.languages){
        if(i18n.locale == this.languages[key].language){
          result = this.languages[key].flag;
        }
      }
      return result;
    }
  },
  components: { simplebar },
  data() {
    return {
      userEmail: '',
      languages: [
        {
          flag: require("@/assets/images/flags/russia.jpg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: "ru",
    };
  },
  methods: {
    getLegalName(){
      axios
      .get(`/v1/users/my-legal-name`)
      .then(resp => {
        this.$store.dispatch('setLegalName', resp.data.name);
        this.legalName = resp.data.name;
      })
      .catch(err => {
        this.$store.dispatch("addNotification", {
          text: err.response.data.message,
          time: 6,
          color: "danger"
        });
      });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    setLanguage(locale) {
      let reloadPage = false;
      if(i18n.locale != locale){
        reloadPage = true;
      }
      i18n.locale = locale;
      this.current_language = i18n.locale;
      localStorage.setItem("locale", locale);
      if(reloadPage){
        location.reload();
      }
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.userEmail = userData[0].email;

    let locale = localStorage.getItem("locale");
    if(locale){
      this.setLanguage(locale);
    }

    if(this.legal_request == 0){
      setTimeout(this.getLegalName, 1200);
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
