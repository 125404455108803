//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  name: "Notificator",
  computed: {
    ...mapState({
      list: state => state.notificator.list
    })
  },
  methods: {
    removeNotification(item) {
      this.$store.dispatch("removeNotification", item.id);
    }
  }
};
