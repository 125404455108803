
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog, Vertical, Horizontal },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  methods: {},
  created() {

  }
};
